// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://13.250.242.130:3220/api/v1/en',
  // baseUrl: "http://localhost:3210/api/v1/en",
  baseUrl: "https://testapi.easefix.com/api/v1/en",
  // baseUrl: 'http://192.168.1.189:3200/api/v1/en',
  // baseUrl: 'http://3.122.216.37:3200/api/v1/en',

  //baseUrl: 'http://52.57.134.73:3200/api/v1/en',

  // baseUrl: 'https://18.218.141.42:3220/api/v1/en',
  // baseUrl:'https://api.testmustangyellow.com/api/v1/en',

  // baseUrl: 'http://localhost:3200/api/v1/en',
  mapApiKey: "AIzaSyBH1eLX1SWyWUdYdwYBks05uZai9SWFTuE",

  //STRIPEkEY
  stripe: "pk_test_EqHPTNpWJUZet9Jqqce22Sng",
  // socket: 'https://192.168.1.189:3220'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
