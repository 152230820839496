import { environment } from "../environments/environment";
import { NgForm } from "@angular/forms";

const baseUrl = environment.baseUrl;

export const Globals = {
  appName: "Tradesmen",
  defaultImage:
    "https://ondemandstartup.s3.us-west-2.amazonaws.com/profileImages/1000X1000/1540906183439-icon_default_image.png",
  defaultCurrency: "£",

  regex: {
    email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    ssn: /\d{3}-\d{2}-\d{4}/,
    date: /\d{2}\/\d{2}\/\d{4}/,

    // password must be 8 digits and contain 1 uppercase and lowercase letters, 1 special character, 1 numeric
    passwordStrength:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    passRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
  },

  // to be used with the TextMask Module from angular2-text-mask package
  masks: {
    phone: [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    date: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
    ssn: [/[0-9]/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
    zip: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
  },

  urls: {
    login: baseUrl + "/admin/logIn",
    send2Fa: baseUrl + "/admin/send/verification-code",
    logout: baseUrl + "/admin/logout",
    uploadImage: baseUrl + "/upload/profile-image",
    uploadMedia: baseUrl + "/upload/service-images",
    uploadPortfolio: baseUrl + '/admin/upload-portfolio',
    getPrfixes: baseUrl + "/country/codes",
    getAllLanguages: baseUrl + "/active-languages",
    getLanguageLevels: baseUrl + "/language-level",
    getCSVDetails: baseUrl + "/user-detail-to-csv",
    getCSVJob: baseUrl + "/job-to-csv",
    gdprLoging: baseUrl + "/admin/view-info",
    getJobChatHistory: baseUrl + "/job-chathistory-to-csv",
    deleteUserCompletely: baseUrl + "/admin/mark/delete",
    TradeAdmin: {
      activeBlockUser: baseUrl + "/admin/block-user-account",
      workers: {
        fetchSPList:
          baseUrl +
          "/admin/fetch-sp-list?status=:status&jobTypeId=:jobTypeId&isBlocked=:isBlocked&accountType=:accountType&isAddedBySheet=:isAddedBySheet&search_text=:search_text&limit=:limit&offset=:offset",
        fetchJobTypes: baseUrl + "/admin/fetch-services-list?limit=100&offset=0",
        fetchProfileData:
          baseUrl + "/admin/fetch-sp-profile-data?workerId=:workerId",
        fetchSpDetails: baseUrl + "/admin/fetch-sp-details?workerId=:workerId",
        fetchNewSpUser:
          baseUrl +
          "/admin/fetch-new-sp-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&limit=:limit&offset=:offset&listType=:listType",
        fetchSpJobList:
          baseUrl +
          "/admin/fetch-sp-jobs?workerId=:workerId&search_text=:search_text&status=:status&limit=:limit&offset=:offset",
        fetchSpJobDetails: baseUrl + "/admin/fetch-sp-job-detail/:jobId",
assignBid: baseUrl + "/user/job/:bidId/assign/bid",
unassignBid: baseUrl + "/user/job/:bidId/unassign/bid",
        approveWorker: baseUrl + "/admin/approve-worker-account",
        rejectWorker: baseUrl + "/admin/reject-worker-account",
        getNewCount: baseUrl + "/admin/get/new-worker-count",
        addWorkersBulk: baseUrl + "/add-worker-bulk",
        editWorker: {
          getProfile: baseUrl + "/admin/get-worker-profile?workerId=:workerId",
          updateWorkerProfile:
            baseUrl + "/admin/update-worker-profile?workerId=:workerId",
          getWorkerServices:
            baseUrl + "/admin/get-worker-services?workerId=:workerId",
          updateWorkerServices: baseUrl + "/admin/update-worker-service",
          getIdDoc:
            baseUrl + "/admin/get-worker-identitydoc?workerId=:workerId",
          updateIdDoc: baseUrl + "/admin/update-worker-identitydoc",
          getProfDoc:
            baseUrl + "/admin/get-worker-professional-doc?workerId=:workerId",
          getPortfolio:
            baseUrl + "/admin/get-worker-portfolio?workerId=:workerId",
          updateProfDoc: baseUrl + "/admin/update-worker-professional-doc",
          updatePortfolio: baseUrl + "/admin/update-worker-portfolio",
          getBankDetails: baseUrl + "/admin/get-worker-bank-info/:workerId",
          updateBankDetails:
            baseUrl + "/admin/update-worker-bank-info/:workerId",
          getUserLanguageDetails:
            baseUrl + "/admin/get-worker-languages?workerId=:workerId",
          updateUserLanguages: baseUrl + "/admin/update-worker-language",
          verifyCertificate:
            baseUrl + "/admin/verify/certificate?workerId=:workerId",
        },
      },
      users: {
        fetchReferraledUsersList:
          baseUrl +
          "/admin/fetch-referral-users-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&totalJobSort=:totalJobSort&activeJobSort=:activeJobSort&limit=:limit&offset=:offset",
        fetchUsersList:
          baseUrl +
          "/admin/fetch-users-list?status=:status&jobTypeId=:jobTypeId&search_text=:search_text&totalJobSort=:totalJobSort&activeJobSort=:activeJobSort&limit=:limit&offset=:offset",
        fetchUsersJobLists:
          baseUrl +
          "/admin/fetch-user-jobs-list?userId=:userId&search_text=:search_text&limit=:limit&offset=:offset&status=:status",
        fetchUserJobDetails:
          baseUrl + "/admin/fetch-user-job-detail?jobId=:jobId",
        fetchUsersActivity:
          baseUrl + "/admin/fetch/users-activity",
        fetchUsersPhoneNumbers:
          baseUrl+"/admin/fetch/phone-numbers"
      },
      jobs: {
        fetchJobsList:
          baseUrl +
          "/admin/fetch-jobs-list?status=:status&search_text=:search_text&dateTime=:dateTime&jobTypeId=:jobTypeId&startDate=:startDate&endDate=:endDate&limit=:limit&offset=:offset",
        editJob: baseUrl + '/admin/edit-job'
      },
      notification: {
        getNotifications: baseUrl + "/admin/general/notifications/:userType",
        postNotifications: baseUrl + "/admin/update/general-notifications",
      },
      RolesSecurity: {
        getRoles: baseUrl + "/admin/subAdmins/listing",
        AddRoles: baseUrl + "/admin/subAdmin/create",
        getSingleRole: baseUrl + "/admin/subAdmin/:subAdminId",
        updateRoles: baseUrl + "/admin/subAdmin/:subAdminId",
      },
      Referrals: {
        getReferrals:
          baseUrl +
          "/referral-listing?userType=:userType&limit=:limit&offset=:offset&search_text=:search_text",
        getSettingsData: baseUrl + "/admin/fetch/referral",
        setSettingsData: baseUrl + "/admin/update/referral",
        getReferralListing : baseUrl +"/admin/referralList",
        updateReferral : baseUrl +"/admin/referralCode"
      },
      Loging: {
        getLogs: baseUrl + "/activity/get/logs",
      },
    },

    authentication: {
      getCurrentUser: baseUrl + "/admin/currentAccount",
      logout: baseUrl + "/admin/logout",
    },

    dashBoard: {
      analytics: baseUrl + "/admin/dashboard-analytics",
    },

    notification: {
      getNotifications: baseUrl + "/user/fetch/notifications?isRead=:isRead",
    },

    post: {
      headerNotifcations: baseUrl + "/admin/fetch/notification",
    },

    map: {
      activeJobs: baseUrl + "/admin/fetch-active-sp-list",
      onlineSp: baseUrl + "/admin/fetch-inactive-sp-list",
      offlineSp: baseUrl + "/admin/fetch-offline-sp-list",
      count: baseUrl + "/admin/fetch-sp-count",

      // updated URL For Complete Job
      completeJob: baseUrl + "/sp/job/:jobId/complete-job-by-Admin", 

      cancelJob: baseUrl + "/admin/cancel-job",
      finishJob: baseUrl + "/admin/",
      viewChat: baseUrl + "/admin/chat/history/:jobId?workerId=:workerId",
      workers: baseUrl + "/admin/fetch-workers"
    },

    job: {
      detail: baseUrl + "/admin/fetch-sp-job-detail",
      updateStatus: baseUrl + "/update-job/:jobId?status=:status",



    },

    settings: {
      profile: {
        update: baseUrl + "/admin/updateProfile",
        get: baseUrl + "/admin/currentAccount",
      },

      commission: {
        get: baseUrl + "/admin/get/commission",
        update: baseUrl + "/admin/update/commission",
        getRanges: baseUrl + "/admin/fetch/ranges",
        updateRanges: baseUrl + "/admin/update/ranges",
        addRange: baseUrl + "/admin/add/range",
        editRange: baseUrl + "/admin/edit/range",
      },

      services: {
        list: baseUrl + "/admin/fetch-services-list",
        get: baseUrl + "/admin/get-service",
        add: baseUrl + "/admin/add-service",
        update: baseUrl + "/admin/update-service",
        delete: baseUrl + "/admin/delete-service",
      },

      promocodes: {
        list: baseUrl + "/admin/get/promocodes",
        add: baseUrl + "/admin/add/promocode",
        update: baseUrl + "/admin/promocode/promoCodeStatusUpdate",
        fetch: baseUrl + "/admin/get/promocode",
        delete: baseUrl + "/admin/delete/promocode",
      },

      reasons: {
        add: baseUrl + "/admin/add/reasons",
        get: baseUrl + "/admin/get/reasons",
        update: baseUrl + "/admin/update/reason",
      },

      payouts: {
        list: baseUrl + "/admin/get-worker-earnings",
        get: baseUrl + "/admin/get-worker-earning-detail",
        pay: baseUrl + "/admin/payout",
      },

      serviceCharges: {
        get: baseUrl + "/admin/get/setting",
        edit: baseUrl + "/admin/update/setting",
      },

      termsCondition: {
        list: baseUrl + "/admin/get/termsconditions",
        add: baseUrl + "/admin/add/termscondition",
        fetch: baseUrl + "/admin/get/termscondition",
      },
      privacyPolicy: {
        list: baseUrl + "/admin/get/privacypolicies",
        add: baseUrl + "/admin/add/privacypolicy",
        fetch: baseUrl + "/admin/get/privacypolicy",
      },
    },
  },
};

export const checkIfOnlySpaces = (form: NgForm, control: string) => {
  // value exists but only spaces
  if (
    form.controls[control].value &&
    form.controls[control].value.trim().length === 0
  ) {
    form.controls[control].setValue("");
    return form.controls[control].setErrors({ required: true });
  } else if (form.controls[control].value) {
    // trim the value
    form.controls[control].setValue(form.controls[control].value.trim());
  } else {
    form.controls[control].setValue("");
  }
};
