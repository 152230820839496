import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../services/http-service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Globals } from '../../Globals';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  globals = Globals;

  httpSub$: Subscription = null;

  model: any = {}

  isLoadingResults: boolean = false;
  show2FA: boolean = false;


  constructor(private service: HttpService, private router: Router, private toastrService: ToastrService) { }

  ngOnInit() {
    this.model = {
      code: ''
    }
  }

  login() {
    const params = {
      email: this.model.email,
      password: this.model.password,
    };

    this.isLoadingResults = true;

    this.httpSub$ = this.service.postRequest(this.globals.urls.send2Fa, params)
      .pipe(
        map(res => res),
      )
      .subscribe(
        res => {
          this.isLoadingResults = false;
          if (res.success == 1) {
            localStorage.setItem('TradeMen-admin-data', JSON.stringify(res.data.account));
            // this.show2FA=true;
            this.service.showSuccess('User signed in successfully', 'Sign In');
            this.router.navigate(['/dashboard']);
          }
          else if (res.success == 0) {
            this.service.showError(res['message'], 'Sign In');
          }
        },
        err => {
          this.isLoadingResults = false;
          this.service.showError(err.error.message);
        }
      );
  }


  login2Fa() {
    const params = {
      email: this.model.email,
      password: this.model.password,
      code: this.model.code
    };
    this.isLoadingResults = true;
    this.httpSub$ = this.service.postRequest(this.globals.urls.login, params)
      .pipe(
        map(res => res),
      )
      .subscribe(
        res => {
          this.isLoadingResults = false;
          if (res.success == 1) {
            localStorage.setItem('TradeMen-admin-data', JSON.stringify(res.data.account));
            // this.show2FA=true;
            this.service.showSuccess('User signed in successfully', 'Sign In');
            this.router.navigate(['/dashboard']);
          }
          else if (res.success == 0) {
            this.service.showError(res['message'], 'Sign In');
          }
        },
        err => {
          this.isLoadingResults = false;
          this.service.showError(err.error.message);
        }
      );
  }

}
