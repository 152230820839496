import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/services/http-service';
import { Subscription } from 'rxjs';
import { Globals } from 'src/app/Globals';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  private globals = Globals;
  httpSub$: Subscription = null

  constructor(private httpService: HttpService, private router: Router) { }

  ngOnInit() {
    this.httpSub$ = this.httpService.getRequest(this.globals.urls.authentication.logout)
      .subscribe(
        res =>{
          localStorage.removeItem('TradeMen-admin-data');
          this.router.navigate(['/login']);
        },
        
        err => this.httpService.showError(err['message']),
      );
  }

  ngOnDestroy() {
    if(this.httpSub$) this.httpSub$.unsubscribe();
  }

}
